

const Footer = () => {
  return (
    <div className=' fixed  w-full text-center bottom-[0px] flex flex-row justify-center items-center h-8 bg-gray-700 text-white'>
      <p>&copy; Rostislav Szkandera 2024</p>
    </div>
  )
}

export default Footer
